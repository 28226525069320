import React from "react";
import AtCharitableSection from "../components/Careers/AtCharitableSection";
import BambooHrSection from "../components/Careers/BambooHrSection";
import BannerSection from "../components/Careers/BannerSection";
import BenefitsAtCI from "../components/Careers/BenefitsAtCI";
import TopEmployer from "../components/Careers/TopEmployer";
import Layout from "../components/Layout/Layout";

const pageInfo = {
  parent_path: "/about",
  path: "/careers",
  title: "Join the team",
  description:
    "View current open positions, and apply to join our team. We love working with talented and open-minded people who strive to make a difference.",
};

const Careers = () => {
  return (
    <Layout pageInfo={pageInfo}>
      <BannerSection />
      <AtCharitableSection />
      <TopEmployer />
      <BenefitsAtCI />
      <BambooHrSection />
    </Layout>
  );
};

export default Careers;
