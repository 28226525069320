import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const SectionWrap = styled.section`
  padding: 80px 0;

  @media (max-width: 992px) {
    padding: 0 0 40px;
  }
`;

const StyledH1 = styled.h1`
  color: #282828;
  font-size: 30px;
  font-weight: 300;
  line-height: 37px;
  margin-bottom: 30px;
`;

const StyledH2 = styled.h2`
  color: #282828;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  margin: 0 0 26px;
`;

const StyledH3 = styled.h3`
  color: #282828;
  font-family: ProximaNova-Semibold;
  font-size: 20px;
  line-height: 24px;
  line-height: 32px;
  width: 90%;
`;

const StyledRow = styled(Row)`
  @media (max-width: 767px) {
    margin: 0;
  }
`;

const StyledCol = styled(Col)`
  position: relative;
  &::before {
    background-image: url(/careers/charitable-impact-dots.png);
    background-repeat: no-repeat;
    background-size: contain;
    bottom: -110px;
    content: "";
    height: 200px;
    left: -10px;
    position: absolute;
    width: 200px;

    @media (max-width: 991px) {
      bottom: 40px;
      left: 6px;
    }

    @media (max-width: 767px) {
      top: -15px;
    }
  }

  @media (max-width: 767px) {
    margin-top: 30px !important;
  }
`;

const CharitableImpactImgWraper = styled.div`
  position: relative;
  z-index: 99;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;

    @media only screen and (max-width: 767px) {
      display: none;
    }
  }

  &::before {
    background-image: url(/careers/charitable-bar-2.png);
    bottom: 0;
    right: -55px;
    height: 24px;
    width: 47px;
  }

  &::after {
    background-image: url(/careers/charitable-bar-1.png);
    bottom: 30px;
    right: -55px;
    height: 42px;
    width: 47px;
  }
`;

const AtCharitableSection = () => {
  return (
    <SectionWrap>
      <Container>
        <StyledRow className="justify-content-md-center">
          <Col md={11}>
            <Row>
              <StyledH1>
                At Charitable Impact, we know that everyone has something to
                give. That's why we’re leading the movement to make giving an
                intentional and joyful part of daily life for everyone.
              </StyledH1>
              <Col md={6}>
                <StyledH2>
                  We love working with talented, creative, and open-minded
                  people who strive to make a difference. We also look for
                  people who share our core values of imagination, courage,
                  respect, kindness, and generosity—the guiding principles we
                  stand by and incorporate into our work every day.
                </StyledH2>
                <StyledH3>
                  So join us, and let's change the world together!
                </StyledH3>
              </Col>

              <StyledCol md={6}>
                <CharitableImpactImgWraper>
                  <img
                    src="/careers/career-img-1.jpg"
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                  />
                </CharitableImpactImgWraper>
              </StyledCol>
            </Row>
          </Col>
        </StyledRow>
      </Container>
    </SectionWrap>
  );
};

export default AtCharitableSection;
