import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const SectionWrap = styled.section`
  padding: 90px 0;
`;

const StyledH2 = styled.h2`
  color: #282828;
  font-size: 30px;
  font-weight: 300;
  line-height: 37px;
  margin: 0 0 15px;
`;

const StyledH3 = styled.h3`
  color: #282828;
  font-family: ProximaNova-Light !important;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  margin: 0 0 15px;
`;

const ImageCol = styled(Col)`
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &::before {
    background-image: url(/careers/benefits-dots.svg);
    bottom: 10px;
    height: 300px;
    right: 0;
    width: 166px;

    @media (max-width: 992px) {
      bottom: -65px;
    }

    @media (max-width: 767px) {
      bottom: 10px;
    }
  }

  &::after {
    background-image: url(/careers/benefits-frame.svg);
    height: 247px;
    right: 10px;
    top: -20px;
    width: 228px;
  }

  @media (max-width: 767px) {
    margin-bottom: 60px;
  }
`;

const GiftImageWrap = styled.div`
  position: relative;
  z-index: 9;
`;

const ListWrap = styled.div``;

const ListItem = styled.div`
  font-size: 16px;
  line-height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style-position: outside;
  list-style-type: none;
  margin-bottom: 20px;
  padding: 0.21428571em 0;
  table-layout: fixed;

  &::before {
    background-color: #449c9f;
    border-radius: 2px !important;
    color: #fff;
    content: "\\2713";
    display: inline-block;
    height: 17px;
    line-height: 21px;
    margin: 5px 10px 0 0;
    min-width: 17px;
    text-align: center;
  }
`;

const ListHeading = styled.span`
  color: #424242;
  font-family: ProximaNova-Semibold;
`;

const ListText = styled.span``;

const BenefitsAtCI = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={12} lg={12}>
            <Row className="align-items-center">
              <Col
                md={7}
                className="order-2 order-md-1 text-center text-sm-start"
              >
                <StyledH2>Benefits at Charitable Impact</StyledH2>
                <StyledH3>
                  We're committed to building an inclusive and collaborative
                  workplace that values equity and welcomes everyone. Our team
                  members enjoy competitive salaries, purposeful perks, and a
                  culture that gives back and has fun doing it.
                </StyledH3>
              </Col>

              <ImageCol md={5} className="order-1 order-md-2">
                <GiftImageWrap>
                  <img
                    src="/careers/career-img-2.jpg"
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                  />
                </GiftImageWrap>
              </ImageCol>
            </Row>

            <Row>
              <Col md={12} className="mt-5">
                <Row>
                  <Col md={4}>
                    <ListWrap
                      role="list"
                      data-sal-duration="1000"
                      data-sal-delay="0"
                    >
                      <ListItem role="listitem">
                        <div>
                          <ListHeading>
                            <strong>Extended health care benefits</strong>
                          </ListHeading>
                          <br />
                          <ListText className="sub-benefits-text">
                            Vision, dental and paramedicals
                          </ListText>
                        </div>
                      </ListItem>
                    </ListWrap>
                  </Col>

                  <Col md={4}>
                    <ListWrap
                      role="list"
                      data-sal-duration="1000"
                      data-sal-delay="0"
                    >
                      <ListItem role="listitem">
                        <div>
                          <ListHeading>
                            <strong>Annual health care spending account</strong>
                          </ListHeading>
                          <br />
                          <ListText className="sub-benefits-text">
                            To spend on what's important to you
                          </ListText>
                        </div>
                      </ListItem>
                    </ListWrap>
                  </Col>

                  <Col md={4}>
                    <ListWrap
                      role="list"
                      data-sal-duration="1000"
                      data-sal-delay="0"
                    >
                      <ListItem role="listitem">
                        <div>
                          <ListHeading>
                            <strong>Professional development</strong>
                          </ListHeading>
                          <br />
                          <ListText className="sub-benefits-text">
                            We support our team members in achieving their
                            professional goals
                          </ListText>
                        </div>
                      </ListItem>
                    </ListWrap>
                  </Col>

                  <Col md={4}>
                    <ListWrap
                      role="list"
                      data-sal-duration="1000"
                      data-sal-delay="0"
                    >
                      <ListItem role="listitem">
                        <div>
                          <ListHeading>
                            <strong>RRSP matching</strong>
                          </ListHeading>
                          <br />
                          <ListText className="sub-benefits-text">
                            To encourage you to save for your future
                          </ListText>
                        </div>
                      </ListItem>
                    </ListWrap>
                  </Col>

                  <Col md={4}>
                    <ListWrap
                      role="list"
                      data-sal-duration="1000"
                      data-sal-delay="0"
                    >
                      <ListItem role="listitem">
                        <div>
                          <ListHeading>
                            <strong>Monthly donation matching</strong>
                          </ListHeading>
                          <br />
                          <ListText className="sub-benefits-text">
                            To inspire you to give to the causes you care about
                          </ListText>
                        </div>
                      </ListItem>
                    </ListWrap>
                  </Col>

                  <Col md={4}>
                    <ListWrap
                      role="list"
                      data-sal-duration="1000"
                      data-sal-delay="0"
                    >
                      <ListItem role="listitem">
                        <div>
                          <ListHeading>
                            <strong>Rest and relaxation</strong>
                          </ListHeading>
                          <br />
                          <ListText className="sub-benefits-text">
                            Minimum three weeks paid annual vacation for all
                            team members
                          </ListText>
                        </div>
                      </ListItem>
                    </ListWrap>
                  </Col>

                  <Col md={4}>
                    <ListWrap
                      role="list"
                      data-sal-duration="1000"
                      data-sal-delay="0"
                    >
                      <ListItem role="listitem">
                        <div>
                          <ListHeading>
                            <strong>Flexibility</strong>
                          </ListHeading>
                          <br />
                          <ListText className="sub-benefits-text">
                            Flexible work schedules and remote working options
                          </ListText>
                        </div>
                      </ListItem>
                    </ListWrap>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default BenefitsAtCI;
