import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import axios from "axios";

const SectionWrap = styled.section`
  padding: 50px 0 200px;

  .BambooHR-ATS-board {
    text-align: left;
  }

  .BambooHR-ATS-board h2 {
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    font-size: 19px;
    font-weight: normal;
    color: #555;
    border-bottom: 1px solid #a0a0a0 !important;
    padding-bottom: 9px;
    margin: 0;
    text-align: left;
  }

  .BambooHR-ATS-board ul {
    font-size: 12px;
    list-style: none;
    padding: 10px 0 0 0;
    margin: 0;
  }
  .BambooHR-ATS-board li {
    color: #777;
    list-style: none !important;
    margin: 0 !important;
    clear: both;
  }
  .BambooHR-ATS-board ul ul {
    padding: 2px 20px 12px 20px;
    border-bottom: 1px solid #d7d7d7;
    margin-bottom: 10px;
  }
  .BambooHR-ATS-board ul > ul:last-of-type {
    margin-bottom: 0px;
  }
  .BambooHR-ATS-board a {
    font-size: 16px;
    font-weight: bold;
    line-height: 1.9em;
  }
  .BambooHR-ATS-board .BambooHR-ATS-Location {
    float: right;
    padding-top: 9px;
  }
  @font-face {
    font-family: "Source Sans Pro";
    font-style: normal;
    font-weight: 400;
    src: local("Source Sans Pro"), local("SourceSansPro-Regular"),
      url(//themes.googleusercontent.com/static/fonts/sourcesanspro/v5/ODelI1aHBYDBqgeIAH2zlBM0YzuT7MdOe03otPbuUS0.woff)
        format("woff");
  }
  #BambooHR-Footer {
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #999999;
    text-align: right;
    margin: 9px 9px 0px 0px;
  }
  #BambooHR-Footer img {
    display: inline;
  }

  .BambooHR-ATS-board p.blankState {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    color: #cdcdcd;
    clear: both;
    border-bottom: solid #d7d7d7 1px;
    font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 25px 0 35px;
  }
  .BambooHR-ATS-board p.blankState strong {
    font-weight: 600px;
  }
`;

const StyledH2 = styled.h2``;

const BambooHrSection = () => {
  const [jobListings, setJobListings] = useState("Loading...");

  useEffect(() => {
    const getJobPosts = async () => {
      return await axios
        .get("https://chimp.bamboohr.com/jobs/embed2.php")
        .then((result) => {
          setJobListings(result.data);
          return result.data;
        });
    };
    getJobPosts();
  }, []);

  return (
    <SectionWrap>
      <Container>
        <div className="text-center">
          <StyledH2>Current open positions</StyledH2>
          <div
            id="openPosition"
            dangerouslySetInnerHTML={{ __html: jobListings }}
          ></div>
          <div id="BambooHR-Footer">
            Powered by
            <a href="http://www.bamboohr.com" target="_blank" rel="external">
              <img
                src="https://resources.bamboohr.com/images/footer-logo.png"
                alt="BambooHR - HR software"
                loading="lazy"
              />
            </a>
          </div>
        </div>
      </Container>
    </SectionWrap>
  );
};

export default BambooHrSection;
