import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

const SectionWrap = styled.section`
  background-color: #f5f8fc;
  padding: 80px 0;
`;

const TopEmployeeCol = styled(Col)`
  position: relative;

  &::before {
    background-image: url(/careers/charitable-impact-shape-bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 340px;
    left: -88px;
    position: absolute;
    top: -30px;
    width: 397px;

    @media (max-width: 767px) {
      height: 260px;
      top: -50px;
      width: 279px;
    }
  }

  @media only screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  z-index: 9;
`;

const StyledLink = styled.a`
  bottom: 15px;
  position: absolute;
  right: 145px;
`;

const StyledLink2 = styled(StyledLink)`
  right: 70px;
`;

const StyledH2 = styled.h2`
  color: #282828;
  font-size: 30px;
  line-height: 37px;
  margin: 0 0 15px;
  font-family: ProximaNova-Light;
  font-weight: 600;
`;

const StyledH3 = styled.h3`
  color: #282828;
  font-family: ProximaNova-Light;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
  margin: 0 0 15px;
`;

const TopEmployer = () => {
  return (
    <SectionWrap>
      <Container>
        <Row className="justify-content-md-center">
          <Col md={12} lg={11}>
            <Row className="align-items-center">
              <TopEmployeeCol md={6}>
                <ImageWrapper>
                  <img
                    src="/careers/top-employers.png"
                    className="img-fluid"
                    alt=""
                    loading="lazy"
                  />
                  <StyledLink
                    href="https://www.greatplacetowork.ca/en/best-workplaces/british-columbia-2019"
                    target="_blank"
                    className="greateplacework-1"
                  >
                    &nbsp;{" "}
                    <img
                      src="https://d1wjn4fmcgu4dn.cloudfront.net/web/EN-Best-Workplaces-British-Columbia.png"
                      style={{ width: "65px", height: "111px" }}
                      alt=""
                      loading="lazy"
                    />
                  </StyledLink>
                  <StyledLink2
                    href="https://www.greatplacetowork.ca/en/"
                    target="_blank"
                    className="greateplacework"
                  >
                    <img
                      src="https://corpchimpstage.wpengine.com/wp-content/themes/chimp-theme/inc/assets/images/careers/great-place.svg"
                      alt=""
                      loading="lazy"
                    />
                  </StyledLink2>
                </ImageWrapper>
              </TopEmployeeCol>

              <Col md={6}>
                <StyledH2>A top employer</StyledH2>
                <StyledH3>
                  It's easier to do your best work when you're part of a great
                  team. Flexibility, opportunities to grow, and friendly team
                  members are just some of the reasons that make us a great
                  place to work.
                </StyledH3>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

export default TopEmployer;
