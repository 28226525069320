import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

const SectionWrap = styled.section`
  background-image: url(/careers/wiggle.png);
  background-position: bottom;
  background-size: cover;
  min-height: 500px;
  overflow: hidden;
  position: relative;
`;

const Bannericon = styled.span`
  left: 12%;
  position: absolute;
  top: -11.4%;
`;

const TileImages = styled.div`
  background-position: 50% !important;
  height: 520px;
  position: absolute;
  top: 0;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  width: 100%;
  z-index: 1;
`;

const BannerContent = styled.div`
  display: block;
  margin: 110px auto auto;
  padding: 1rem;
  position: relative;
  text-align: center;
  width: 520px;
  z-index: 999;

  @media (max-width: 992px) {
    margin-top: 70px;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 1rem 0;
  }
`;

const StyledH1 = styled.h1`
  color: #424242;
`;

const BannerImage = styled.span`
  display: inline-block;
  vertical-align: bottom;

  &:nth-of-type(1),
  &:nth-of-type(2) {
    @media (max-width: 992px) {
      display: none;
    }
  }

  &:nth-of-type(3) {
    img {
      @media only screen and (max-width: 1199px) {
        height: 244px;
        width: 270px;
      }

      @media (max-width: 490px) {
        height: 244px;
        width: 80px;
        object-fit: cover;
      }
    }
  }

  &:nth-of-type(6) {
    img {
      @media only screen and (max-width: 767px) {
        height: 244px;
        width: 140px;
      }

      @media (max-width: 490px) {
        height: 244px;
        width: 140px;
        object-fit: cover;
      }
    }
  }
`;

const CareersImgBg = styled.span`
  position: relative;
  display: inline-block;
  vertical-align: bottom;

  &::before {
    background-image: url(/careers/dots.png);
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    height: 120px;
    position: absolute;
    right: 50px;
    top: -270px;
    width: 203px;
    z-index: -9;

    @media (max-width: 992px) {
      display: none;
    }
  }
`;

const BannerSection = () => {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <SectionWrap id="careers-banner">
      <Bannericon className="animate__animated animate__fadeInUp animate__slow text-right">
        <img
          src="/careers/shape-left-top.png"
          className="d-none d-xl-block"
          alt=""
          loading="lazy"
        />
      </Bannericon>

      <TileImages style={{ transform: `translateY(-${offsetY * 2}px)` }}>
        <Container>
          <Row
            className="justify-content-md-center"
            style={{ position: "relative" }}
          >
            <Col md={12} className="text-center">
              <BannerContent>
                <StyledH1>Our people make us great</StyledH1>
              </BannerContent>

              <BannerImage
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-delay="300"
              >
                <img src="/careers/photo-1.png" alt="" loading="lazy" />
              </BannerImage>

              <BannerImage
                className="px-1"
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-delay="400"
              >
                <img src="/careers/bar-1.svg" alt="" loading="lazy" />
              </BannerImage>

              <BannerImage
                className="pe-1"
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-delay="500"
              >
                <img src="/careers/photo-2.jpg" alt="" loading="lazy" />
              </BannerImage>

              <BannerImage
                className="pe-1"
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-delay="600"
              >
                <img src="/careers/bar-2.svg" alt="" loading="lazy" />
              </BannerImage>

              <BannerImage
                className="pe-1"
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-delay="700"
              >
                <img src="/careers/bar-3.png" alt="" loading="lazy" />
              </BannerImage>

              <BannerImage
                data-sal="slide-up"
                data-sal-duration="1000"
                data-sal-delay="800"
              >
                <img src="/careers/img-banner-1.png" alt="" loading="lazy" />
                <CareersImgBg />
              </BannerImage>
            </Col>
          </Row>
        </Container>
      </TileImages>
    </SectionWrap>
  );
};

export default BannerSection;
